import {Close} from "@mui/icons-material"
import {Box, Divider, IconButton, Sheet, Stack, Typography} from "@mui/joy"
import type {FC} from "react"
import React from "react"
import {useCart} from "utils/useCart"
import {CartContent} from "components/CartContent"

const CART_WIDTH = 400

export const Cart: FC = () => {
  const {cartState, cartDispatch} = useCart()

  return (
    <>
      {cartState.isOpen && (
        <div
          onClick={() => cartDispatch({type: "toggleShowCart"})}
          style={{
            zIndex: 1999,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
          }}
        ></div>
      )}

      <Sheet
        component={Stack}
        sx={(theme) => ({
          position: "fixed",
          right: cartState.isOpen ? 0 : `calc(-${CART_WIDTH}px)`, // - ${theme.spacing(2)})`,
          transition: "all 0.2s ease-in-out",
          top: 0,
          bottom: 0,
          zIndex: 2000,
          width: "100%",
          height: "100vh",
          maxWidth: 400,
          boxShadow: theme.shadow.md
        })}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{m: 2}}
        >
          <Typography level="h2">Cart</Typography>
          <IconButton
            size="sm"
            variant="soft"
            onClick={() => cartDispatch({type: "toggleShowCart"})}
            aria-label="Close cart"
          >
            <Close />
          </IconButton>
        </Stack>

        <Divider sx={{mt: 1}} />

        <Box sx={{overflowY: "auto", p: 2, flexGrow: 1}}>
          <CartContent />
          <div style={{width: "100px", height: "48px"}} />
        </Box>
      </Sheet>
      {/* <ExcitingModal open={open} setOpen={setOpen} /> used for if site needs to block all purchases again*/}
    </>
  )
}
