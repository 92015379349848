import type {PriceRange} from "sdk/sdk"

export function formatCents(cents: number): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "usd"
  }).format(cents / 100)
}

export function formatPriceRange(priceRange: PriceRange): string {
  if (priceRange.low === priceRange.high) return formatCents(priceRange.low)

  return `${formatCents(priceRange.low)} - ${formatCents(priceRange.high)}`
}

export function kebobCaseToTitleCase(kebobCase: string): string {
  return kebobCase
    .split("-")
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(" ")
}

export function getYouTubeIdFromURL(youTubeURL: string): string | null {
  let url: URL

  try {
    url = new URL(youTubeURL)
  } catch {
    return null
  }

  const watch = url.searchParams.get("v")
  if (watch) return watch

  const embed = url.pathname.split("/").pop()
  if (embed) return embed

  return null
}

export function getYouTubeThumbnailLink(youTubeURL: string): string | null {
  const id = getYouTubeIdFromURL(youTubeURL)
  if (!id) return null

  return `https://img.youtube.com/vi/${id}/hqdefault.jpg`
}

export function slugify(text: string): string {
  return (
    text
      .toLowerCase()
      // convert non-alphanumeric characters to hyphens
      .replace(/[^a-z0-9]+/g, "-")
      // remove hyphens from the start and end of the string
      .replace(/^-+|-+$/g, "")
  )
}

export function kabobCaseToTitleCase(kabobCase: string): string {
  return kabobCase
    .split("-")
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(" ")
}
