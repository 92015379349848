import {useEffect, useState} from "react"
import {LocalStorageKey} from "utils/constants"
import type {Api} from "./sdk"
import {LiveApi, UserSession} from "./sdk"
import {getApi, getSession, logout} from "./sessionHelpers"

export interface UseSessionManagerReturn {
  unauthenticatedApi: Api
  changeBackendURL: (backendURL: string) => void
  session: UserSession | null
  authenticate: (userToken: string) => void
}

export const useSessionManager = (): UseSessionManagerReturn => {
  const [api, setApi] = useState(() => getApi())
  const [session, setSession] = useState(getSession(api))

  // Refresh the user token on initial page load to keep it from expiring
  useEffect(() => {
    removeJwtFromURL()

    session?.auth
      .refreshToken()
      .then(changeToken)
      .catch(() => logout())
  }, [])

  // Update the user token in local storage when it changes
  useEffect(() => {
    if (typeof window === "undefined") return

    if (session) {
      localStorage.setItem(LocalStorageKey.USER_TOKEN, session.userToken)
    } else {
      localStorage.removeItem(LocalStorageKey.USER_TOKEN)
    }
  }, [session])

  function changeToken(userToken: string) {
    setSession(new UserSession(api, userToken))
  }

  function changeBackendURL(backendURL: string) {
    setApi(new LiveApi(backendURL))
  }

  return {
    unauthenticatedApi: api,
    changeBackendURL,
    session,
    authenticate: changeToken
  }
}

function removeJwtFromURL() {
  const url = new URL(window.location.href)

  if (url.searchParams.has("jwt")) {
    url.searchParams.delete("jwt")
    window.history.replaceState({}, "", url.toString())
  }
}
