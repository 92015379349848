import {Close} from "@mui/icons-material"
import {Button, Sheet, Stack, Typography} from "@mui/joy"
import {Link} from "gatsby"
import type {FC} from "react"
import React, {useEffect, useState} from "react"
import type {Giveaway} from "sdk/sdk"
import {useSession} from "sdk/useSession"
import {useCountdown} from "utils/useCountdown"

const BANNER_STORAGE_KEY = "bannerClosed"

export const GiveawayBanner: FC = () => {
  const [giveaway, setGiveaway] = useState<Giveaway>()
  const [closeBanner, setCloseBanner] = useState(
    typeof window === "undefined"
      ? true
      : sessionStorage.getItem(BANNER_STORAGE_KEY) === "true"
  )
  const {unauthenticatedApi} = useSession()

  useEffect(() => {
    unauthenticatedApi.giveaway
      .query({
        condition: {at: {GreaterThan: new Date().toISOString()}},
        limit: 1,
        orderBy: ["at"]
      })
      .then((g) => (giveaway === undefined ? setGiveaway(g.at(0)) : null))
  }, [giveaway])

  const {days, hours, minutes, seconds} = useCountdown(
    giveaway?.at ? new Date(giveaway.at) : undefined
  )

  const hasAnyTime = Boolean(days || hours || minutes || seconds)
  const isPassed = days < 0 || hours < 0 || minutes < 0 || seconds < 0

  if (closeBanner || giveaway === undefined || !hasAnyTime || isPassed)
    return null

  const significantUnit = days ? "d" : hours ? "h" : minutes ? "m" : "s"

  return (
    <Sheet
      variant="soft"
      color="primary"
      sx={(theme) => ({
        p: 1,
        borderTop: `1px solid ${theme.palette.primary[200]}`,
        position: "relative"
      })}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          gap: 2,
          mx: 4,
          flexDirection: {
            xs: "column",
            sm: "row"
          }
        }}
      >
        <Typography
          fontWeight="bold"
          textAlign="center"
          sx={{
            display: {
              sm: "block"
            },
            maxWidth: {
              xs: "unset",
              sm: 300
            }
          }}
        >
          {`${giveaway.title} Giveaway Ends Soon!`}
        </Typography>

        <Stack direction="row" gap={2}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            mr={2}
          >
            <Stack
              alignItems="center"
              sx={{
                display: {
                  xs: significantUnit === "d" ? "flex" : "none",
                  md: "flex"
                }
              }}
            >
              <Typography fontWeight="bold" fontSize="large" lineHeight="100%">
                {days}
              </Typography>
              <Typography textAlign="start" level="body2">
                Days
              </Typography>
            </Stack>

            <Typography
              mx={1}
              alignSelf="flex-start"
              fontSize="large"
              fontWeight="bold"
              lineHeight="100%"
              sx={{
                display: {
                  xs: "none",
                  md: "flex"
                }
              }}
            >
              {":"}
            </Typography>

            <Stack
              alignItems="center"
              sx={{
                display: {
                  xs: significantUnit === "h" ? "flex" : "none",
                  md: "flex"
                }
              }}
            >
              <Typography fontWeight="bold" fontSize="large" lineHeight="100%">
                {hours}
              </Typography>
              <Typography textAlign="start" level="body2">
                Hrs
              </Typography>
            </Stack>

            <Typography
              mx={1}
              alignSelf="flex-start"
              fontSize="large"
              fontWeight="bold"
              lineHeight="100%"
              sx={{
                display: {
                  xs: "none",
                  md: "flex"
                }
              }}
            >
              {":"}
            </Typography>

            <Stack
              alignItems="center"
              sx={{
                display: {
                  xs: significantUnit === "m" ? "flex" : "none",
                  md: "flex"
                }
              }}
            >
              <Typography fontWeight="bold" fontSize="large" lineHeight="100%">
                {minutes}
              </Typography>
              <Typography textAlign="start" level="body2">
                Mins
              </Typography>
            </Stack>

            <Typography
              mx={1}
              alignSelf="flex-start"
              fontSize="large"
              fontWeight="bold"
              lineHeight="100%"
              sx={{
                display: {
                  xs: "none",
                  md: "flex"
                }
              }}
            >
              {":"}
            </Typography>

            <Stack
              alignItems="center"
              sx={{
                display: {
                  xs: significantUnit === "s" ? "flex" : "none",
                  md: "flex"
                }
              }}
            >
              <Typography fontWeight="bold" fontSize="large" lineHeight="100%">
                {seconds}
              </Typography>
              <Typography level="body2">Secs</Typography>
            </Stack>
          </Stack>

          <Button component={Link} size="sm" to={"/friendship-dues"}>
            View Now!
          </Button>
        </Stack>

        <Button
          variant="plain"
          aria-label="Close Giveaway Banner"
          size="sm"
          onClick={() => {
            setCloseBanner(true)
            sessionStorage.setItem(BANNER_STORAGE_KEY, "true")
          }}
          sx={{
            position: "absolute",
            right: 0
          }}
        >
          <Close fontSize="small" />
        </Button>
      </Stack>
    </Sheet>
  )
}
