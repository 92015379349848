const countryCurrencyMap = {
  US: "usd",
  CA: "cad",
  MX: "mxn"
} satisfies Record<string, string>

export const defaultLanguage = "en"
export const defaultCountry = "US"
export const defaultCurrency = "usd"

// Using en-US for language until we support localization

export const languageCode =
  // (typeof window !== "undefined" ? navigator.language.split("-")[0] : null) ??
  defaultLanguage
export const countryCode =
  // (typeof window !== "undefined" ? navigator.language.split("-")[1] : null) ??
  defaultCountry
export const currencyCode = countryCurrencyMap[countryCode] ?? defaultCurrency

export function localize<T>(value: Record<string, T>): T {
  return (
    value[languageCode] ??
    value[defaultLanguage] ??
    value[countryCode] ??
    value[defaultCountry] ??
    value[currencyCode] ??
    value[defaultCurrency]
  )
}

export function localizeEnUS<T>(value: Record<string, T>): T {
  return (
    value[defaultLanguage] ?? value[defaultCountry] ?? value[defaultCurrency]
  )
}
