import {Menu, Person, ShoppingCart, Star} from "@mui/icons-material"
import {
  Badge,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  Modal,
  ModalClose,
  ModalDialog,
  Sheet,
  Stack,
  Typography
} from "@mui/joy"
import {Link as GatsbyLink} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import type {FC} from "react"
import React, {useState} from "react"
import {useCart} from "utils/useCart"

const navigationLinks: {label: string; to: string}[] = [
  {label: "All Products", to: "/products"},
  {label: "UTV/ATV", to: "/products/utv-atv"},
  {label: "Snowmobile", to: "/products/snowmobile-cat"},
  {label: "Motorcycle", to: "/products/motorcycle"},
  {label: "OEM Parts", to: "/oem-assemblies"},
  {label: "Shop Talk", to: "/shop-talk"}
]

const HeaderNav: FC = () => {
  const {cartState} = useCart()

  const [showMobileMenu, setShowMobileMenu] = useState(false)

  return (
    <>
      <Sheet
        color="neutral"
        sx={{
          display: "flex",
          alignItems: "center",
          py: 1.5
        }}
        component="header"
      >
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <GatsbyLink to="/">
              <StaticImage
                src="../../images/logo-color.png"
                height={35}
                alt="Madhouse Motorsports Logo"
                placeholder="none"
              />
            </GatsbyLink>

            <Stack
              direction="row"
              sx={{
                gap: {xs: 0, sm: 2}
              }}
            >
              <IconButton
                variant="plain"
                component={GatsbyLink}
                to="/friendship-dues"
                aria-label="Friendship Dues"
                sx={{display: {xs: "flex", sm: "none"}}}
              >
                <Star />
              </IconButton>

              <Button
                size="sm"
                variant="outlined"
                component={GatsbyLink}
                to="/friendship-dues"
                sx={{display: {xs: "none", sm: "flex"}}}
              >
                Friendship Dues
              </Button>

              <Badge
                badgeContent={cartState.items.reduce(
                  (a, b) => a + b.quantity,
                  0
                )}
                badgeInset="20%"
                size="sm"
              >
                <IconButton
                  variant="plain"
                  component={GatsbyLink}
                  to="/checkout/cart"
                  aria-label="Cart"
                >
                  <ShoppingCart />
                </IconButton>
              </Badge>

              <IconButton
                variant="plain"
                component={GatsbyLink}
                to="/app/my-account"
                aria-label="My Account"
              >
                <Person />
              </IconButton>
            </Stack>
          </Stack>
        </Container>
      </Sheet>

      <Sheet variant="soft" component="nav">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            gap: {
              xs: 0,
              sm: 3
            },
            m: 0,
            p: 0,
            listStyle: "none"
          }}
          component="ul"
        >
          {/* Mobile menu button */}
          <Box component="li" sx={{display: {xs: undefined, sm: "none"}}}>
            <Button
              variant="plain"
              color="neutral"
              sx={{fontWeight: "bold"}}
              fullWidth
              startDecorator={<Menu />}
              onClick={() => setShowMobileMenu(true)}
            >
              MENU
            </Button>
          </Box>

          {/* Desktop nav links */}
          {navigationLinks.map(({label, to}) => (
            <li key={label}>
              <Link
                color="neutral"
                component={GatsbyLink}
                to={to}
                sx={{
                  display: {xs: "none", sm: "block"},
                  textDecoration: "none !important",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: "black",
                  py: 1,
                  textAlign: "center"
                }}
              >
                {label}
              </Link>
            </li>
          ))}
        </Stack>
      </Sheet>

      <Modal open={showMobileMenu} onClose={() => setShowMobileMenu(false)}>
        <ModalDialog
          sx={{
            inset: 0,
            borderRadius: 0,
            transform: "none",
            maxWidth: "unset",
            maxHeight: "unset"
          }}
        >
          <ModalClose />
          <Typography>Menu</Typography>

          <Divider sx={{my: 2}} />

          <List>
            {navigationLinks.map(({label, to}) => (
              <ListItem key={label}>
                <Link
                  to={to}
                  component={GatsbyLink}
                  onClick={() => setShowMobileMenu(false)}
                >
                  {label}
                </Link>
              </ListItem>
            ))}
          </List>
        </ModalDialog>
      </Modal>
    </>
  )
}

export default HeaderNav
