import type {
  CartItem,
  CustomItem,
  OEMAssembly,
  Product,
  ProductVariant
} from "sdk/sdk"
import {localize} from "./localization"

export function totalAdditivePrice(
  product: Product,
  selectedOptions: Record<string, string | null>
): number {
  return Object.entries(selectedOptions).reduce(
    (acc, [dimensionId, valueId]) => {
      const optionDimension = product.options.find((o) => o._id === dimensionId)
      const value = optionDimension?.values.find((v) => v._id === valueId)
      if (!optionDimension?.calculated || !value) return acc

      return acc + value.additivePrice
    },
    0
  )
}

export function getCartItemPrices(params: {
  cartItem: CartItem
  product: Product | undefined
  variant: ProductVariant | undefined
  assembly: OEMAssembly | undefined
  custom: CustomItem | undefined | null
}): {salePrice: number; listPrice: number} {
  const {cartItem, product, variant, assembly, custom} = params

  if (variant && product && cartItem.product) {
    const additive = totalAdditivePrice(product, cartItem.product.options)
    return {
      salePrice: cartItem.quantity * (localize(variant.salePrices) + additive),
      listPrice: cartItem.quantity * (localize(variant.listPrices) + additive)
    }
  }

  const oemPart = assembly?.parts.find((p) => p.id === cartItem.oem?.part)

  if (oemPart) {
    return {
      salePrice: cartItem.quantity * localize(oemPart.price),
      listPrice: cartItem.quantity * localize(oemPart.price)
    }
  }

  if (custom) {
    return {
      salePrice: cartItem.quantity * custom.price,
      listPrice: cartItem.quantity * custom.price
    }
  }

  return {
    salePrice: 0,
    listPrice: 0
  }
}

export function areCartItemsSame(a: CartItem, b: CartItem): boolean {
  if (a.oem && b.oem) {
    return a.oem.part === b.oem.part && a.oem.assembly === b.oem.assembly
  }

  if (a.product && b.product) {
    const productAndVariantMatch =
      a.product.product === b.product.product &&
      a.product.variant === b.product.variant

    if (!productAndVariantMatch) return false

    const allOptionKeys = Object.keys({
      ...a.product.options,
      ...b.product.options
    })

    return allOptionKeys.every(
      (key) => a.product?.options[key] === b.product?.options[key]
    )
  }

  return false
}
