import {Alert, Button, Divider, Link, Stack, Typography} from "@mui/joy"
import {Link as GatsbyLink} from "gatsby"
import type {FC} from "react"
import React, {useMemo} from "react"
import {getCartItemPrices} from "utils/cartHelpers"
import {FREE_SHIPPING_THRESHOLD_CENTS} from "utils/constants"
import {formatCents} from "utils/helpers"
import {useCart} from "utils/useCart"
import {CartLineItem} from "./CartLineItem"

export const CartContent: FC = () => {
  const {cartState, cartDispatch} = useCart()

  const hasVariableShipping = useMemo(() => {
    const variableShippingProducts = cartState.data.products.filter(
      (p) => p.variableShipment
    )

    return variableShippingProducts.some((p) =>
      cartState.items.some((i) => i.product?.product === p._id)
    )
  }, [cartState.data, cartState.items])

  return (
    <>
      {(() => {
        if (cartState.items.length === 0)
          return (
            <Typography sx={{my: 4}} fontStyle="italic">
              Your cart is empty
            </Typography>
          )

        return (
          <Stack spacing={4} mb={3}>
            {cartState.items.map((item, index) => (
              <CartLineItem
                key={`${index}-${item.product?.variant}-${item.oem?.part}`}
                cartItem={item}
                product={cartState.data.products.find(
                  (p) => p._id === item.product?.product
                )}
                variant={cartState.data.variants.find(
                  (v) => v._id === item.product?.variant
                )}
                assembly={cartState.data.assemblies.find(
                  (a) => a._id === item.oem?.assembly
                )}
                custom={item.custom}
                handleChangeQty={(qty) => {
                  if (qty < 1) cartDispatch({type: "removeItem", index})
                  else cartDispatch({type: "changeQty", index, qty})
                }}
              />
            ))}
          </Stack>
        )
      })()}
      <Divider />

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontWeight="bold">Subtotal</Typography>
        <Typography fontSize="1.3rem">
          {formatCents(
            cartState.items.reduce(
              (acc, cartItem) =>
                acc +
                getCartItemPrices({
                  cartItem,
                  product: cartState.data.products.find(
                    (p) => p._id === cartItem.product?.product
                  ),
                  variant: cartState.data.variants.find(
                    (v) => v._id === cartItem.product?.variant
                  ),
                  assembly: cartState.data.assemblies.find(
                    (a) => a._id === cartItem.oem?.assembly
                  ),
                  custom: cartItem.custom
                }).salePrice,
              0
            )
          )}
        </Typography>
      </Stack>

      {hasVariableShipping ? (
        <Alert color="warning" sx={{mt: 1}}>
          <div>
            <Typography fontSize="0.9rem">
              The shipping cost for an item in your cart must be manually
              calculated. We will send you an additional invoice after you place
              your order.
            </Typography>
            <Typography fontSize="0.9rem" mt={1}>
              For more information, or if you have questions before ordering,
              email us at{" "}
              <Link
                target="_blank"
                href="mailto:support@madhousemotorsports.com"
                rel="noreferrer"
                color="neutral"
              >
                support@madhousemotorsports.com
              </Link>
              .
            </Typography>
          </div>
        </Alert>
      ) : (
        <>
          <Typography level="body2" textColor="secondary" fontStyle="italic">
            Shipping and taxes calculated at checkout
          </Typography>

          <Typography
            mt={1}
            level="body2"
            textColor="secondary"
            fontStyle="italic"
          >
            Free shipping on orders over ${FREE_SHIPPING_THRESHOLD_CENTS / 100}.
            Some exclusions may apply.
          </Typography>
        </>
      )}

      {cartState.items.length > 0 && (
        <Button
          onClick={() =>
            cartState.isOpen && cartDispatch({type: "toggleShowCart"})
          }
          component={GatsbyLink}
          to="/checkout/payment"
          fullWidth
          sx={{mt: 2}}
          endDecorator={<span>&rarr;</span>}
        >
          Continue to Checkout
        </Button>
      )}
    </>
  )
}
