import {Container, Link, Sheet, Stack, Typography} from "@mui/joy"
import {Link as GatsbyLink} from "gatsby"
import React from "react"

export default function Footer() {
  return (
    <Sheet
      variant="soft"
      component="footer"
      sx={{"& a": {color: "black !important"}}}
    >
      <Container sx={{p: 3}}>
        <Stack
          direction="row"
          gap={4}
          flexWrap="wrap"
          // Increase touch target size for links
          sx={{"& a": {py: 1.2}}}
        >
          <Stack>
            <Typography fontWeight="bold">About Us</Typography>
            <Link component={GatsbyLink} color="neutral" to="/who-we-are">
              Who We Are
            </Link>

            <Link component={GatsbyLink} color="neutral" to="/privacy-policy">
              Privacy Policy
            </Link>

            <Link
              component={GatsbyLink}
              color="neutral"
              to="/terms-and-agreement"
            >
              Terms and Agreement
            </Link>
          </Stack>

          <Stack>
            <Typography fontWeight="bold">Customer Resources</Typography>

            <Link component={GatsbyLink} color="neutral" to="/friendship-dues">
              Friendship Dues
            </Link>

            <Link component={GatsbyLink} color="neutral" to="/return-policy">
              Item Return
            </Link>
          </Stack>

          <Stack>
            <Typography fontWeight="bold">Contact Us</Typography>
            <Link
              target="_blank"
              href="mailto:support@madhousemotorsports.com"
              rel="noreferrer"
              color="neutral"
            >
              support@madhousemotorsports.com
            </Link>

            <Link
              target="_blank"
              href="tel:(801) 317-8505"
              rel="noreferrer"
              color="neutral"
            >
              (801) 317-8505
            </Link>
          </Stack>
        </Stack>
      </Container>
      <div id="comm100-button-5b284211-e733-41bb-8dfb-91b188a7c769"></div>
    </Sheet>
  )
}
