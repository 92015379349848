import type {FC, ReactElement} from "react"
import React, {useState} from "react"
import type {SizedImage} from "sdk/sdk"

export interface SizedImageDisplayProps {
  image: SizedImage
  alt: string
  largestSize?: number
  imgProps?: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
  pictureProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLPictureElement>,
    HTMLPictureElement
  >
}

export const SizedImageDisplay: FC<SizedImageDisplayProps> = ({
  image,
  largestSize,
  imgProps,
  pictureProps,
  alt
}) => {
  const [didLoad, setDidLoad] = useState(false)

  return (
    <picture {...pictureProps} style={{...pictureProps?.style}}>
      {generateSourceElements(image.webp, "image/webp", largestSize)}
      {generateSourceElements(image.jpg, "image/jpeg", largestSize)}
      <img
        src={image.base ?? image.source ?? ""}
        alt={alt}
        {...imgProps}
        style={{
          backgroundColor: didLoad ? undefined : "lightgrey",
          color: "transparent",
          display: "block",
          ...imgProps?.style
        }}
        onLoad={() => setDidLoad(true)}
      />
    </picture>
  )
}

function generateSourceElements(
  images: SizedImage["jpg" | "webp"],
  type: "image/jpeg" | "image/webp",
  largestSize?: number
): ReactElement[] {
  let sortedImages = Object.entries(images)
    .sort(([a], [b]) => parseInt(a) - parseInt(b))
    .map(([size, url]) => [parseInt(size), url])
    .filter(([_, url]) => !!url) as [number, string][]

  const cutoffIndex =
    largestSize !== undefined
      ? sortedImages.findIndex(([size], index, arr) => {
          if (index === arr.length - 1) return true
          return (
            Math.abs(arr[index + 1][0] - largestSize) >
            Math.abs(size - largestSize)
          )
        })
      : undefined

  if (cutoffIndex !== undefined)
    sortedImages = sortedImages.slice(0, cutoffIndex + 1)

  return sortedImages
    .reverse()
    .map(([size, url]) => (
      <source
        key={size}
        srcSet={url}
        media={`(min-width: ${size / 2}px)`}
        type={type}
      />
    ))
}
