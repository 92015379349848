import {CssBaseline, CssVarsProvider, StyledEngineProvider} from "@mui/joy"
import Layout from "components/Layout"
import type {
  WrapPageElementBrowserArgs,
  WrapPageElementNodeArgs,
  WrapRootElementBrowserArgs,
  WrapRootElementNodeArgs
} from "gatsby"
import React from "react"
import {SessionProvider} from "sdk/useSession"
import theme from "theme"
import {CartProvider} from "utils/useCart"

export const wrapPageElement = ({
  element,
  props
}: WrapPageElementNodeArgs & WrapPageElementBrowserArgs) => {
  return (
    <CssVarsProvider theme={theme}>
      <CssBaseline />
      <Layout location={props.location}>{element}</Layout>
    </CssVarsProvider>
  )
}

export const wrapRootElement = ({
  element
}: WrapRootElementNodeArgs & WrapRootElementBrowserArgs) => {
  return (
    <StyledEngineProvider injectFirst>
      <SessionProvider>
        <CartProvider>{element}</CartProvider>
      </SessionProvider>
    </StyledEngineProvider>
  )
}
