import {extendTheme} from "@mui/joy/styles"

export default extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          // https://mui.com/joy-ui/customization/theme-builder/
          "50": "#e3f2fd",
          "100": "#bbdefb",
          "200": "#90caf9",
          "300": "#64b5f6",
          "400": "#42a5f5",
          "500": "#2196f3",
          "600": "#1e88e5",
          "700": "#1976d2",
          "800": "#1565c0",
          "900": "#0d47a1"
        }
      }
    }
  },

  typography: {
    h1: {
      fontSize: "2.5rem",
      marginBottom: "2rem",
      textAlign: "center"
    },
    h2: {
      fontSize: "2rem",
      marginTop: "2rem",
      marginBottom: "0.8rem"
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: "bold"
    }
  }

  // fontFamily: {
  //   display: "'Inter', var(--joy-fontFamily-fallback)",
  //   body: "'Inter', var(--joy-fontFamily-fallback)"
  // }
})
