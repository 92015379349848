import {Add, Remove} from "@mui/icons-material"
import {
  Box,
  IconButton,
  Link,
  Sheet,
  Skeleton,
  Stack,
  Typography
} from "@mui/joy"
import {SizedImageDisplay} from "components/SizedImageDisplay"
import {getCartItemPrices} from "utils/cartHelpers"
import type {FC} from "react"
import React, {useMemo} from "react"
import type {
  CartItem,
  CustomItem,
  OEMAssembly,
  Product,
  ProductVariant
} from "sdk/sdk"
// import {getCartItemSalePrice} from "utils/cartHelpers"
import {formatCents} from "utils/helpers"
import {localize} from "utils/localization"
import {useCart} from "utils/useCart"

interface CartLineItemProps {
  cartItem: CartItem
  product: Product | undefined
  variant: ProductVariant | undefined
  assembly: OEMAssembly | undefined
  custom?: CustomItem | null | undefined
  handleChangeQty: (qty: number) => void
}

export const CartLineItem: FC<CartLineItemProps> = (props) => {
  const {cartItem, product, variant, assembly, handleChangeQty, custom} = props
  const {cartDispatch} = useCart()

  const part = assembly?.parts.find((p) => p.id === cartItem.oem?.part)

  const itemName = custom
    ? custom.name
    : product
    ? localize(product.name)
    : assembly
    ? `${assembly.name} - ${part?.name}`
    : ""

  const image = custom
    ? null
    : variant?.images.at(0) ??
      product?.images.at(0) ??
      part?.image ??
      assembly?.image

  const link = useMemo(() => {
    if (custom) return null
    if (assembly) return `/oem-assemblies/${assembly._id}`
    else if (product && variant) {
      const path = `/products/${product._id}`
      const params = new URLSearchParams(cartItem.product?.options)
      params.set("variant", variant._id)
      return `${path}?${params.toString()}`
    } else {
      return null
    }
  }, [assembly, product, variant])

  const {listPrice, salePrice} = getCartItemPrices({
    custom,
    cartItem,
    product,
    variant,
    assembly
  })

  if (!product && !assembly && !variant && !custom) {
    return (
      <Sheet>
        <Skeleton height={80} variant="rectangular" />
      </Sheet>
    )
  }

  return (
    <Sheet
      component={Stack}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack direction="row" alignItems="flex-start" spacing={2} width="100%">
        {(() => {
          if (!image) return null

          return (
            <SizedImageDisplay
              image={image}
              alt="Product thumbnail"
              imgProps={{
                style: {
                  width: 60,
                  aspectRatio: 1,
                  objectFit: "cover",
                  borderRadius: 4,
                  overflow: "hidden"
                }
              }}
              pictureProps={{style: {marginTop: "0.5rem"}}}
              largestSize={60}
            />
          )
        })()}

        <Box width="100%">
          <Link
            disabled={!link}
            level="body1"
            fontWeight="bold"
            href={link ?? "#"}
            color="neutral"
            onClick={() => cartDispatch({type: "toggleShowCart"})}
          >
            {itemName ?? "Product not found"}
          </Link>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton
                size="sm"
                variant="plain"
                color="neutral"
                onClick={() => handleChangeQty(cartItem.quantity - 1)}
                aria-label="Decrease quantity"
              >
                <Remove />
              </IconButton>
              <Typography level="body1" textAlign="center" width={15}>
                {cartItem.quantity}
              </Typography>
              <IconButton
                size="sm"
                variant="plain"
                color="neutral"
                onClick={() => handleChangeQty(cartItem.quantity + 1)}
                aria-label="Increase quantity"
              >
                <Add />
              </IconButton>
            </Stack>
            <Box>
              {listPrice === salePrice ? (
                <Typography level="body2">{formatCents(listPrice)}</Typography>
              ) : (
                <>
                  <Typography level="body3" color="neutral">
                    <s>{formatCents(listPrice)}</s>
                  </Typography>
                  <Typography level="body2" color="success" fontWeight="bold">
                    {formatCents(salePrice)}
                  </Typography>
                </>
              )}
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Sheet>
  )
}
