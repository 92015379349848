import type {FC, PropsWithChildren} from "react"
import React, {createContext, useContext, useEffect, useState} from "react"
import type {User, UserSession} from "sdk/sdk"
import type {UseSessionManagerReturn} from "./useSessionManager"
import {useSessionManager} from "./useSessionManager"

export type AuthenticationState =
  | {status: "unauthenticated"}
  | {status: "loading"; session: UserSession}
  | {status: "error"; session: UserSession}
  | {
      status: "authenticated"
      currentUser: User
      setCurrentUser: (user: User) => void
      session: UserSession
    }

export type SessionContextType = Pick<
  UseSessionManagerReturn,
  "authenticate" | "unauthenticatedApi"
> & {
  authState: AuthenticationState
}

const SessionContext = createContext<SessionContextType>(
  {} as SessionContextType
)

export const SessionProvider: FC<PropsWithChildren> = (props) => {
  const {children} = props
  const {authenticate, session, unauthenticatedApi} = useSessionManager()

  const [currentUser, setCurrentUser] = useState<User | "loading" | "error">(
    "loading"
  )

  useEffect(() => {
    if (!session) return

    session.auth
      .getSelf()
      .then(setCurrentUser)
      .catch(() => setCurrentUser("error"))
  }, [session])

  return (
    <SessionContext.Provider
      value={{
        authenticate,
        unauthenticatedApi,
        authState: (() => {
          if (!session) return {status: "unauthenticated"}

          if (currentUser === "loading") return {status: "loading", session}

          if (currentUser === "error") return {status: "error", session}

          return {
            status: "authenticated",
            currentUser,
            setCurrentUser,
            session
          }
        })()
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export function useSession() {
  return useContext(SessionContext)
}
