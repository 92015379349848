import {LocalStorageKey} from "utils/constants"
import type {Api} from "./sdk"
import {LiveApi, UserSession} from "./sdk"

export function getApi(): Api {
  const backendUrl = process.env.GATSBY_BACKEND_HTTP_URL
  if (!backendUrl) throw new Error("GATSBY_BACKEND_HTTP_URL env is not set")

  return new LiveApi(backendUrl)
}

export function getSession(api: Api): UserSession | null {
  if (typeof window === "undefined") return null

  const localStorageToken =
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    localStorage.getItem(LocalStorageKey.USER_TOKEN) || undefined

  const url = new URL(window.location.href)
  const searchParamsJwt = url.searchParams.get("jwt")

  const tokenToUse = localStorageToken ?? searchParamsJwt
  return tokenToUse ? new UserSession(api, tokenToUse) : null
}

export function logout(): void {
  localStorage.removeItem(LocalStorageKey.USER_TOKEN)
  window.location.reload()
}
