import {
  AttachMoney,
  Google,
  Inventory,
  Label,
  Loyalty,
  Menu,
  Person,
  ShoppingCart,
  Star,
  VideoLibrary
} from "@mui/icons-material"
import {
  Box,
  Button,
  Divider,
  Modal,
  ModalClose,
  ModalDialog,
  Sheet,
  Stack,
  Typography
} from "@mui/joy"
import type {PageProps} from "gatsby"
import {Link} from "gatsby"
import type {FC, PropsWithChildren, ReactNode} from "react"
import React, { useEffect } from "react"
import Footer from "./Footer"
import {GiveawayBanner} from "./GiveawayBanner"
import HeaderNav from "./Header"
import {Cart} from "./Cart"

const ClientLayout: FC<{children: ReactNode}> = ({children}) => {
  return (
    <Stack sx={{minHeight: "100vh"}}>
      <Cart />

      <HeaderNav />

      <GiveawayBanner />

      <Box flexGrow={1} pb={3} component="main">
        {children}
      </Box>
      <Footer />
    </Stack>
  )
}

const AdminLayout: FC<{children: ReactNode}> = ({children}) => {
  const [showMobileMenu, setShowMobileMenu] = React.useState(false)

  const SIDEBAR_WIDTH = "13rem"

  const navigationLinks: {label: string; to: string; icon: ReactNode}[] = [
    {
      label: "Unfulfilled",
      to: "/app/admin/unfulfilled-orders",
      icon: <Star />
    },
    {label: "All Orders", to: "/app/admin/orders", icon: <ShoppingCart />},
    {label: "Products", to: "/app/admin/products", icon: <Inventory />},
    {label: "Revenue", to: "/app/admin/revenue", icon: <AttachMoney />},
    {label: "Shop Talk", to: "/app/admin/shop-talk", icon: <VideoLibrary />},
    {label: "Giveaways", to: "/app/admin/giveaways", icon: <Loyalty />},
    {label: "Tag SEO", to: "/app/admin/tags", icon: <Label />},
    {label: "Users", to: "/app/admin/users", icon: <Person />},
    {
      label: "Merchant Listings",
      to: "/app/admin/merchant-listings",
      icon: <Google />
    }
  ]

  return (
    <>
      <Sheet
        sx={(theme) => ({
          width: SIDEBAR_WIDTH,
          p: 1,
          position: "fixed",
          height: "100vh",
          overflowY: "auto",
          borderRight: `1px solid ${theme.palette.divider}`,
          display: {xs: "none", sm: "flex"}
        })}
      >
        <Stack spacing={1} mt={3}>
          {navigationLinks.map(({label, to, icon}) => {
            const isSelected = location.pathname.startsWith(to)

            return (
              <Button
                key={to}
                component={Link}
                to={to}
                color={isSelected ? "primary" : "neutral"}
                variant={isSelected ? "soft" : "plain"}
                size="sm"
                startDecorator={icon}
                sx={{justifyContent: "flex-start"}}
              >
                {label}
              </Button>
            )
          })}
        </Stack>
      </Sheet>
      <Box sx={{position: "absolute", right: "1rem", top: "1.3rem"}}>
        <Button
          variant="plain"
          color="neutral"
          sx={{fontWeight: "bold", display: {xs: undefined, sm: "none"}}}
          fullWidth
          onClick={() => setShowMobileMenu(true)}
        >
          <Menu sx={{fontSize: "3rem"}} />
        </Button>
      </Box>
      <Box
        pl={{xs: 0, sm: SIDEBAR_WIDTH}}
        minHeight="100vh"
        // sx={(theme) => ({backgroundColor: theme.palette.background.level1})}
      >
        {children}
      </Box>
      <Modal open={showMobileMenu} onClose={() => setShowMobileMenu(false)}>
        <ModalDialog
          sx={{
            inset: 0,
            borderRadius: 0,
            transform: "none",
            maxWidth: "unset",
            maxHeight: "unset"
          }}
        >
          <ModalClose />
          <Typography>Menu</Typography>

          <Divider sx={{my: 2}} />

          {navigationLinks.map(({label, to, icon}) => {
            const isSelected = location.pathname.startsWith(to)

            return (
              <Button
                key={to}
                component={Link}
                to={to}
                color={isSelected ? "primary" : "neutral"}
                variant={isSelected ? "soft" : "plain"}
                size="sm"
                startDecorator={icon}
                sx={{justifyContent: "flex-start"}}
                onClick={() => setShowMobileMenu(false)}
              >
                {label}
              </Button>
            )
          })}
        </ModalDialog>
      </Modal>
    </>
  )
}

const Layout: FC<PropsWithChildren<{location: PageProps["location"]}>> = (
  props
) => {
  const isAdminApp = props.location.pathname.startsWith("/app/admin")

  useEffect(() => {
    const script = document.createElement("script")

    script.innerHTML = `
      var Comm100API=Comm100API||{};(function(t){function e(e){var a=document.createElement("script"),c=document.getElementsByTagName("script")[0];a.type="text/javascript",a.async=!0,a.src=e+t.site_id,c.parentNode.insertBefore(a,c)}t.chat_buttons=t.chat_buttons||[],t.chat_buttons.push({code_plan:"5b284211-e733-41bb-8dfb-91b188a7c769",div_id:"comm100-button-5b284211-e733-41bb-8dfb-91b188a7c769"}),t.site_id=70001389,t.main_code_plan="5b284211-e733-41bb-8dfb-91b188a7c769",e("https://vue.comm100.com/livechat.ashx?siteId="),setTimeout(function(){t.loaded||e("https://standby.comm100vue.com/livechat.ashx?siteId=")},5e3)})(Comm100API||{})
    `

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  if (isAdminApp) return <AdminLayout>{props.children}</AdminLayout>
  else return <ClientLayout>{props.children}</ClientLayout>
}

export default Layout
