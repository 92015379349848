import {useEffect, useState} from "react"

export interface UseCountdownReturn {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export function useCountdown(targetDate: Date | undefined): UseCountdownReturn {
  const [countdownMs, setCountdownMs] = useState<number>(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (!targetDate) return

      const difference = targetDate.getTime() - new Date().getTime()
      setCountdownMs(difference)
    }, 1000)

    return () => clearInterval(interval)
  }, [targetDate?.getTime()])

  const days = Math.floor(countdownMs / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (countdownMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((countdownMs % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countdownMs % (1000 * 60)) / 1000)

  return {days, hours, minutes, seconds}
}
