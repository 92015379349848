import type {FC} from "react"
import React, {createContext, useContext, useEffect, useReducer} from "react"
import type {CartItem, OEMAssembly, Product, ProductVariant} from "sdk/sdk"
import {useSession} from "sdk/useSession"
import {areCartItemsSame} from "./cartHelpers"
import {LocalStorageKey} from "./constants"

interface CartContextType {
  cartState: CartState
  cartDispatch: React.Dispatch<CartAction>
}

const CartContext = createContext<CartContextType>({} as CartContextType)

export const CartProvider: FC<{children: React.ReactNode}> = ({children}) => {
  const {unauthenticatedApi, authState} = useSession()

  const [cartState, cartDispatch] = useReducer(cartReducer, {
    isOpen: false,
    isAddingToCart: false,
    items: [],
    data: {
      products: [],
      variants: [],
      assemblies: []
    }
  })

  useEffect(() => {
    let items: CartItem[] = []

    try {
      if (typeof window === "undefined") throw new Error("SSR")

      const cartJSON = localStorage.getItem(LocalStorageKey.CART)
      if (!cartJSON) throw new Error("No cart in local storage")

      items =
        authState.status === "authenticated"
          ? authState.currentUser.cart
          : JSON.parse(cartJSON)

      // Migrate data from the old cart format
      items.forEach((item) => {
        if (item.product) {
          item.product.options ??= {}
        }
      })
    } catch {
      // noop
    }

    cartDispatch({
      type: "setInitialCartState",
      cartState: {
        isOpen: false,
        isAddingToCart: false,
        items,
        data: {
          products: [],
          variants: [],
          assemblies: []
        }
      }
    })
  }, [authState.status])

  useEffect(() => {
    if (authState.status === "unauthenticated") {
      // Save updated items to local storage
      localStorage.setItem(
        LocalStorageKey.CART,
        JSON.stringify(cartState.items)
      )
    }

    // Save updated items to the server
    if (authState.status === "authenticated") {
      authState.session.user
        .modify(authState.currentUser._id, {cart: {Assign: cartState.items}})
        .then(authState.setCurrentUser)
        .catch(() => alert("Error updating cart"))

      localStorage.setItem(
        LocalStorageKey.CART,
        JSON.stringify(cartState.items)
      )
    }

    const productsToFetch = new Set<string>()
    const variantsToFetch = new Set<string>()
    const assembliesToFetch = new Set<string>()

    cartState.items.forEach((item) => {
      const product = item.product?.product
      const variant = item.product?.variant
      const assembly = item.oem?.assembly

      if (product && !cartState.data.products.some((p) => p._id === product)) {
        productsToFetch.add(product)
      }

      if (variant && !cartState.data.variants.some((v) => v._id === variant)) {
        variantsToFetch.add(variant)
      }

      if (
        assembly &&
        !cartState.data.assemblies.some((a) => a._id === assembly)
      ) {
        assembliesToFetch.add(assembly)
      }
    })

    Promise.all([
      unauthenticatedApi.product.query({
        condition: {_id: {Inside: Array.from(productsToFetch)}}
      }),
      unauthenticatedApi.productVariant.query({
        condition: {_id: {Inside: Array.from(variantsToFetch)}}
      }),
      unauthenticatedApi.oEMAssembly.query({
        condition: {_id: {Inside: Array.from(assembliesToFetch)}}
      })
    ])
      .then(([products, variants, assemblies]) =>
        cartDispatch({
          type: "setData",
          data: {
            products: cartState.data.products.concat(products),
            variants: cartState.data.variants.concat(variants),
            assemblies: cartState.data.assemblies.concat(assemblies)
          }
        })
      )
      .catch(console.error)
  }, [cartState.items, authState.status])

  return (
    <CartContext.Provider value={{cartState, cartDispatch}}>
      {children}
    </CartContext.Provider>
  )
}

export function useCart() {
  const {cartState, cartDispatch} = useContext(CartContext)

  return {cartState, cartDispatch}
}

export interface CartState {
  items: CartItem[]
  isOpen: boolean
  isAddingToCart: boolean
  data: {
    products: Product[]
    variants: ProductVariant[]
    assemblies: OEMAssembly[]
  }
}

export type CartAction =
  | {type: "addToCart"; cartItem: CartItem}
  | {type: "removeItem"; index: number}
  | {type: "changeQty"; index: number; qty: number}
  | {type: "toggleShowCart"}
  | {type: "clearCart"}
  | {type: "setData"; data: CartState["data"]}
  | {type: "setInitialCartState"; cartState: CartState}

export function cartReducer(state: CartState, action: CartAction): CartState {
  switch (action.type) {
    case "addToCart": {
      const existingIndex = state.items.findIndex((item) =>
        areCartItemsSame(item, action.cartItem)
      )

      const newItems = [...state.items]

      if (existingIndex !== -1) {
        newItems[existingIndex].quantity += action.cartItem.quantity

        return {
          ...state,
          isOpen: true,
          items: newItems
        }
      }

      return {
        ...state,
        isOpen: true,
        items: [...state.items, action.cartItem]
      }
    }

    case "removeItem":
      return {
        ...state,
        items: state.items.filter((_, index) => index !== action.index)
      }

    case "changeQty":
      return {
        ...state,
        items: state.items.map((item, index) =>
          index === action.index ? {...item, quantity: action.qty} : item
        )
      }

    case "toggleShowCart":
      return {...state, isOpen: !state.isOpen}

    case "clearCart":
      return {...state, items: []}

    case "setData":
      return {...state, data: action.data}

    case "setInitialCartState":
      return {...action.cartState}
  }
}
